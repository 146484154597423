import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentDisplay from '../components/content-display/content-display';
import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';

const Place = ({ data }) => {
  const content = data.nodePlace;

  const image = {
    src:
      content.relationships.field_image &&
      content.relationships.field_image.max_1600_10_7
        ? content.relationships.field_image.max_1600_10_7.childImageSharp.resolutions.src
        : null,
    alt: content.field_image ? content.field_image.alt : null,
  };

  // Reformat the metatag data from Drupal in a way we can use.
  const metatags = content.metatag.reduce((accumulator, currentVal) => {
    return {
      ...accumulator,
      [currentVal.attributes.name]: currentVal.attributes.content,
    };
  }, {});

  // Filter events associated with this place
  const upcomingEvents = data.allNodeEvent.edges.filter(
    ({ node }) =>
      node.relationships.field_venue &&
      node.relationships.field_venue.__typename === 'node__place' &&
      node.relationships.field_venue.drupal_id === content.drupal_id
  );

  return (
    <DefaultLayout data={data}>
      <SEO
        {...metatags}
        siteConfig={data.site.siteMetadata}
        pageUrl={content.path.alias}
        image={image}
      />
      <ContentDisplay
        {...content}
        imageLocation="sidebar"
        upcomingEvents={upcomingEvents.map(({ node }) => node)}
      />
    </DefaultLayout>
  );
};

Place.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query PlaceQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    nodePlace(path: { alias: { eq: $slug } }) {
      title
      drupal_id
      path {
        alias
      }
      body {
        processed
        summary
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
      field_image {
        alt
      }
      relationships {
        field_image {
          max_1600_10_7 {
            childImageSharp {
              resolutions(width: 470, height: 330, quality: 90) {
                ...GatsbyImageSharpResolutions
              }
            }
          }
          max_1600_16_9 {
            childImageSharp {
              sizes(maxWidth: 992, maxHeight: 558) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
    allNodeEvent(
      sort: { fields: [field_date___value], order: ASC }
      filter: { field_private_event: { ne: true } }
    ) {
      edges {
        node {
          title
          field_date {
            value(formatString: "MMMM D, YYYY")
            end_value(formatString: "MMMM D, YYYY")
          }
          field_image {
            imageDerivatives {
              links {
                max_1600_16_9 {
                  href
                }
              }
            }
          }
          path {
            alias
          }
          relationships {
            field_venue {
              __typename
              ... on node__place {
                drupal_id
                title
                path {
                  alias
                }
              }
              
            }
          }
        }
      }
    }
  }
`;

export default Place;
